<template>
  <div>
    <div :class="{ loading: calculatingPrice }"></div>
    <div class="visible-print-inline-block persons-involved">
      <div>
        <span class="prepared-for"><b>{{ organizationInformation.companyName }}</b></span>
      </div>
      <div>
        <span><b> Prepared By: </b>{{ organizationInformation.preparedBy }} </span>
      </div>
    </div>
    <h6><i>
      <span class="red">*</span> This cost savings tool provides an estimate only and is not a guarantee of actual savings.
      A customer may not rely on an estimate as a complete representation of actual savings experienced through the use of this product.
    </i></h6>
    <Chart :currentArray="currentArray" :oneYearArray="oneYearArray" :nthYearArray="nthYearArray" :year="year"/>
    <ResultTables
      :typeYear="typeYear"
      @oneYearSubscriptionArray="sendArrayToChart($event, 1)"
      @threeYearSubscriptionArray="sendArrayToChart($event, 2)"
      @nthYearSubscriptionArray="sendArrayToChart($event, 2)"
      @currentCostArray="sendArrayToChart($event, 0)"

      @costs="updateCosts($event)"
      @netSavings="updateNetSavings($event)"
      @returnOnInvestment="updateReturnOnInvestment($event)"
    />
    <div v-if="emailMessage" :class="messageError ? 'alert alert-danger' : 'alert alert-success'" role="alert">
      {{ emailMessage }}
    </div>
    <div>
      <router-link class="hide-for-print" :to="{name: 'CostCategories', params: { key: this.roiKey, productKey: this.productKey }}">
        <span class="results-back-button pull-left hide-for-print" id="back-button">
          <span class="glyphicon glyphicon-arrow-left"></span>
          BACK
        </span>
      </router-link>
    </div>
    <button class="hide-for-print pull-right" title="print" type="button" name="button" @click="printWindow">
      <img src="https://d1jqsvv1ta1uec.cloudfront.net/roicalculator/media-editing-print.png">
    </button>
    <button class="hide-for-print pull-right" title="email" type="button" name="button" @click="email">
      <img src="https://d1jqsvv1ta1uec.cloudfront.net/roicalculator/communication-email.png">
    </button>
    <button class="hide-for-print pull-right edit" type="button" @click="setTypeYear">
      <span class="glyphicon glyphicon-pencil"></span>
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResultTables from './ResultTables'
import axios from 'axios'
import Chart from './Chart'
import constants from '../objects/constants'

export default {
    name: 'Results',
    data () {
        return {
            emailMessage: '',
            messageError: false,
            oneYearArray: [],
            nthYearArray: [],
            currentArray: [],
            costs: {},
            netSavings: {},
            returnOnInvestment: {},
            year: 0,
            typeYear: false
        }
    },
    components: {
        Chart,
        ResultTables
    },
    computed: {
        ...mapState([
            'organizationInformation',
            'organizationInput',
            'printConsumableInput',
            'productKey',
            'purchaseOptions',
            'costCategories',
            'calculatingPrice',
            'roiKey'
        ]),
        ...mapGetters({
            costTableRows: 'resultTables/costTableRows'
        })
    },
    methods: {
        updateCosts (event) {
            this.costs = event
        },
        updateNetSavings (event) {
            this.netSavings = event
        },
        updateReturnOnInvestment (event) {
            this.returnOnInvestment = event
        },
        sendArrayToChart (event, updateLine, year) {
            switch (updateLine) {
            case 0: this.currentArray = event; break
            case 1: this.oneYearArray = event; break
            case 2: this.nthYearArray = event.array; this.year = event.year; break
            default: this.oneYearArray = event
            }
        },
        setTypeYear () {
            this.typeYear = !this.typeYear
            this.$emit('typeYear', this.typeYear)
        },
        printWindow () {
            window.print()
        },
        formatPayloadItem (object, payloadItem) {
            payloadItem.rows = []
            for (let [key, value] of Object.entries(object)) {
                // Omit certain fields from the email
                if ((key === 'currencyRate' && this.organizationInformation.currency !== 'AUD') ||
        (key === 'numberOfEMRLicenses' && !constants.isEMR.includes(this.organizationInformation.industry))) {
                    continue
                }
                // Format the variable name so numberOfPrinters becomes Number Of Printers
                let humanText = key.replace(/([A-Z])/g, ' $1').trim()
                humanText = humanText.charAt(0).toUpperCase() + humanText.slice(1)
                // Add prefix and suffix's
                if (
                    key.toLowerCase().includes('reduction') ||
          key.toLowerCase().includes('percentage') ||
          key.toLowerCase().includes('roi')
                ) {
                    value = value.toString() + '%'
                } else if (
                    key.toLowerCase().includes('cost') ||
          key.toLowerCase().includes('expense') ||
          key.toLowerCase().includes('savings') ||
          key.toLowerCase().includes('discount') ||
          key.toLowerCase().includes('professionalservices')
                ) {
                    value = constants[this.organizationInformation.currency] + value.toLocaleString(undefined) /* { maximumFractionDigits: 2 } */
                }
                payloadItem.rows.push({
                    name: key,
                    value: value,
                    humanReadable: humanText
                })
            }
            return payloadItem
        },
        async email () {
            try {
                const statePayloads = [
                    { name: 'Organization Input', value: this.organizationInput },
                    { name: 'Organization Information', value: this.organizationInformation },
                    { name: 'Print Consumable Input', value: this.printConsumableInput },
                    { name: 'Purchase Options', value: this.purchaseOptions },
                    { name: 'Cost Categories', value: this.costCategories },
                    { name: 'Costs', value: this.costs },
                    { name: 'Net Savings', value: this.netSavings },
                    { name: 'Return On Investment', value: this.returnOnInvestment }
                ]
                // Attach State payloads
                const payload = []
                for (const state of statePayloads) {
                    const payloadItem = {}
                    payloadItem.category = state.name
                    payload.push(this.formatPayloadItem(state.value, payloadItem))
                }
                // Attach Cost Category Table data
                const payloadItem = {}
                payloadItem.rows = []
                payloadItem.category = ['Cost Category', 'Current Annual Costs', 'Reduction Via PrinterLogic', 'PrinterLogic Savings']
                for (const row of this.costTableRows) {
                    payloadItem.rows.push({ humanReadable: row.rowName,
                        value: [
                            constants[this.organizationInformation.currency] + row.currentCost.toLocaleString(undefined),
                            row.costReduction.toString() + '%',
                            constants[this.organizationInformation.currency] + row.savings.toLocaleString(undefined)
                        ] })
                }
                payload.push(payloadItem)

                await axios.post('/email/summary', {
                    context: { payload, roiKey: this.roiKey },
                    email: this.organizationInformation.emailAddress
                })
                this.messageError = false
                this.emailMessage = 'Your email was successfully sent'
            } catch (err) {
                this.messageError = true
                this.emailMessage = 'Your email failed to send. Please check to make sure your email is correct and try again later, or contact PrinterLogic support'
                throw err
            }
        }
    }
}
</script>

<style scoped>
h6 {
  text-align: center;
}

button {
  padding: 0.50em 0.75em;
  background-color: #E5E5E5;
}

button:hover {
  background-color: rgba(102, 102, 102, 0.3);
}

.edit {
  padding: 0.4em 0.80em;
  padding-top: 0.6em;
  margin-right: 2em;
}

.glyphicon-pencil {
  color: black;
  font-size: 1.4em;
}

.persons-involved {
  text-align: center;
  padding-bottom: 20px;
  width:100%;
}

.prepared-for {
  font-size: 30px;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

.red {
  color: red;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@page {
  size: auto;
  margin: 0;
}
@media print {
  .licensing-cost-table, .roi-table, .cost-table, .net-savings-table {
    transform: translateY(-10%);
  }
}
@media screen and (max-width: 760px) {
  img {
    width: 1.75em;
  }
}
</style>
