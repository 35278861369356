<template>
  <div>
    <h2>Cost Categories</h2>
    <p><strong><span class="red">*</span> Indicates a required field</strong></p>
    <form class="" method="post" @submit.prevent="submit">
        <div class="form-group required" >
            <div class="row">
                <div class="col-md-8">
                    <label class="control-label" for="server-refresh-cost">Server Refresh Cost per Print Server
                        <span
                            v-tooltip="{
                            content: 'Hardware and software cost for Print Server refresh.',
                            trigger: 'click hover'
                            }">
                            <font-awesome-icon icon="question-circle"/>
                        </span>
                    </label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.serverRefreshCostPerPrintServer"
                      name="serverRefreshCostPerPrintServer"
                      id="server-refresh-cost"
                      :currency="currencySymbol"
                      :min="0"
                      :precision="2"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
                <div class="col-md-4">
                    <label class="control-label" for="refresh-reduction-cost">Refresh cost reduction</label>
                    <vue-numeric
                        class="form-control"
                        v-model="form.refreshCostReduction"
                        name="refreshCostReduction"
                        id="refresh-reduction-cost"
                        currencySymbolPosition="suffix"
                        currency="%"
                        :min="0"
                        :max="100"
                        onfocus="this.select()"
                        :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="form-group required" >
            <div class="row">
                <div class="col-md-8">
                    <label class="control-label" for="server-op-expense">Monthly Operating Expense per Print Server
                        <span
                            v-tooltip="{
                            content: 'Includes any software licensing, maintenance and infrastructure costs.',
                            trigger: 'click hover'
                            }">
                            <font-awesome-icon icon="question-circle"/>
                        </span>
                    </label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.monthlyOperatingExpensePerPrintServer"
                      name="monthlyOperatingExpensePerPrintServer"
                      id="server-op-expense"
                      :currency="currencySymbol"
                      :min="0"
                      :precision="2"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
                <div class="col-md-4">
                    <label class="control-label" for="server-op-expense-reduction">Operating cost reduction</label>
                    <vue-numeric
                        class="form-control"
                        v-model="form.operatingCostReduction"
                        name="operatingCostReduction"
                        id="server-op-expense-reduction"
                        currencySymbolPosition="suffix"
                        currency="%"
                        :min="0"
                        :max="100"
                        onfocus="this.select()"
                        :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="form-group required" >
            <div class="row">
                <div class="col-md-8">
                    <label class="control-label" for="wan-traffic-cost">Total Monthly Cost of WAN Traffic per Employee
                        <span
                            v-tooltip="{
                            content: 'Average monthly cost of WAN traffic per employee per month.',
                            trigger: 'click hover'
                            }">
                            <font-awesome-icon icon="question-circle"/>
                        </span>
                    </label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.totalMonthlyCostOfWanTrafficPerEmployee"
                      name="totalMonthlyCostOfWanTrafficPerEmployee"
                      id="wan-traffic-cost"
                      :currency="currencySymbol"
                      :min="0"
                      :precision="2"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
                <div class="col-md-4">
                    <label class="control-label" for="wan-traffic-reduction">WAN Traffic cost reduction</label>
                    <vue-numeric
                        class="form-control"
                        v-model="form.wanTrafficCostReduction"
                        name="wanTrafficCostReduction"
                        id="wan-traffic-reduction"
                        currencySymbolPosition="suffix"
                        currency="%"
                        :min="0"
                        :max="100"
                        onfocus="this.select()"
                        :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="form-group required" >
            <div class="row">
                <div class="col-md-8">
                    <label class="control-label" for="service-desk-calls">Cost per Print-Related Service Desk Call</label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.costPerPrintRelatedServiceDeskCall"
                      name="costPerPrintRelatedServiceDeskCall"
                      id="service-desk-calls"
                      :currency="currencySymbol"
                      :min="0"
                      :precision="2"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
                <div class="col-md-4">
                    <label class="control-label" for="cost-desk-call-reduction">Service desk call cost reduction</label>
                    <vue-numeric
                        class="form-control"
                        v-model="form.serviceDeskCallCostReduction"
                        name="serviceDeskCallCostReduction"
                        id="cost-desk-call-reduction"
                        currencySymbolPosition="suffix"
                        currency="%"
                        :min="0"
                        :max="100"
                        onfocus="this.select()"
                        :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="form-group required" >
            <div class="row">
                <div class="col-md-8">
                    <label class="control-label" for="cost-admin-hours">Cost per Printer Administrator Hour</label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.costPerPrinterAdminstrationHour"
                      name="costPerPrinterAdminstrationHour"
                      id="cost-admin-hours"
                      :currency="currencySymbol"
                      :min="0"
                      :precision="2"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
                <div class="col-md-4">
                    <label class="control-label" for="pull-print-cost-reduction">Administrator hour cost reduction</label>
                    <vue-numeric
                        class="form-control"
                        v-model="form.administrationHourCostReduction"
                        name="administrationHourCostReduction"
                        id="pull-print-cost-reduction"
                        currencySymbolPosition="suffix"
                        currency="%"
                        :min="0"
                        :max="100"
                        onfocus="this.select()"
                        :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="nav-buttons">
          <button class="link-button" type="submit" id="back-button">
            <span class="glyphicon glyphicon-arrow-left"></span>
            SAVE &amp; BACK
          </button>
          <button type="submit" id="next-button">
            SAVE &amp; NEXT
            <span class="glyphicon glyphicon-arrow-right"></span>
          </button>
        </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VueNumeric from 'vue-numeric'
import { required, between, minValue } from 'vuelidate/lib/validators'
import constants from '../objects/constants'

export default {
    name: 'PrintConsumableInput',
    data: function () {
        return {
            // form input bindings by v-model
            form: {
                serverRefreshCostPerPrintServer: 2000,
                refreshCostReduction: 90,
                monthlyOperatingExpensePerPrintServer: 170,
                operatingCostReduction: 90,
                totalMonthlyCostOfWanTrafficPerEmployee: 1.5,
                wanTrafficCostReduction: 90,
                costPerPrintRelatedServiceDeskCall: 50,
                serviceDeskCallCostReduction: 50,
                costPerPrinterAdminstrationHour: 30,
                administrationHourCostReduction: 60
            }
        }
    },
    components: {
        VueNumeric
    },
    computed: {
        ...mapState([
            'roiKey',
            'productKey'
        ]),
        /* Sets the currency symbol from state */
        currencySymbol () {
            return constants[this.$store.state.organizationInformation.currency]
        }
    },
    mounted () {
    /* loads the form data from state if exist */
        this.form.serverRefreshCostPerPrintServer = this.$store.state.costCategories.serverRefreshCostPerPrintServer
        this.form.refreshCostReduction = this.$store.state.costCategories.refreshCostReduction
        this.form.monthlyOperatingExpensePerPrintServer = this.$store.state.costCategories.monthlyOperatingExpensePerPrintServer
        this.form.operatingCostReduction = this.$store.state.costCategories.operatingCostReduction
        this.form.totalMonthlyCostOfWanTrafficPerEmployee = this.$store.state.costCategories.totalMonthlyCostOfWanTrafficPerEmployee
        this.form.wanTrafficCostReduction = this.$store.state.costCategories.wanTrafficCostReduction
        this.form.costPerPrintRelatedServiceDeskCall = this.$store.state.costCategories.costPerPrintRelatedServiceDeskCall
        this.form.serviceDeskCallCostReduction = this.$store.state.costCategories.serviceDeskCallCostReduction
        this.form.costPerPrinterAdminstrationHour = this.$store.state.costCategories.costPerPrinterAdminstrationHour
        this.form.administrationHourCostReduction = this.$store.state.costCategories.administrationHourCostReduction
    },
    validations: {
        form: {
            serverRefreshCostPerPrintServer: { required, minValue: minValue(0) },
            refreshCostReduction: { required, between: between(0, 100) },
            monthlyOperatingExpensePerPrintServer: { required, minValue: minValue(0) },
            operatingCostReduction: { required, between: between(0, 100) },
            totalMonthlyCostOfWanTrafficPerEmployee: { required, minValue: minValue(0) },
            wanTrafficCostReduction: { required, between: between(0, 100) },
            costPerPrintRelatedServiceDeskCall: { required, minValue: minValue(0) },
            serviceDeskCallCostReduction: { required, between: between(0, 100) },
            costPerPrinterAdminstrationHour: { required, minValue: minValue(0) },
            administrationHourCostReduction: { required, between: between(0, 100) }
        }
    },
    methods: {
        ...mapActions([
            'handleFormSubmit'
        ]),
        submit () {
            // call this.$v.form.$touch() to handle the form $dirty state manualy
            this.$v.form.$touch()
            // if an error exist we stop here and back to check
            if (this.$v.form.$error) return
            /*
        we going to call to updateMethod located on the application store
        and send all the values for every form field.
        The form object has all the fields values like fieldName -> value
       */
            Object.keys(this.form).forEach((field) => {
                const value = this.form[field]
                this.$store.commit('updateCostCategories', { field, value })
            })
            /*
        call to the store handleFormSubmit method
        to change the route and update the application state
      */
            this.handleFormSubmit()
        }
    }
}
</script>

<style>
  .form-group.required .control-label:after, .red {
      color: red;
  }
  .tooltip .tooltip-inner {
    border-radius: 4px;
  }
  .nav-buttons {
    display: flex;
  }
  #back-button {
    align-self: flex-start;
  }
  #next-button {
    margin-left:auto;
  }
  @media screen and (max-width: 326px) {
    .nav-buttons {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    #next-button {
      margin-left: 0em;
      margin-top: 1em;
    }
  }
</style>
