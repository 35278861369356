module.exports = {
    SAAS: 'PRINTERLOGIC_SAAS',
    VIRTUAL: 'PRINTERLOGIC_VIRTUAL_APPLIANCE',
    isEducation: ['EDUCATIONCOMMUNITYCOLLEGE', 'EDUCATIONK12', 'EDUCATIONUNIVERSITY'],
    isEMR: ['HEALTHCARECARECLINICS', 'HEALTHCAREHOSPITALS', 'HOSPITALITY', 'GOVERNMENTCITYCOUNTY', 'GOVERNMENTFEDERALCOUNTRY', 'GOVERNMENTSTATEPROVINCE'],
    EUR: '€',
    GBP: '£',
    AUD: 'AU$',
    USD: '$'
}
