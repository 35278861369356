export default {
    filters: {
        currency: num => {
            if (num === null) return ''
            return Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        },
        percentage: num => {
            if (num === null) return ''
            return `${Math.round(Number(num))}%`
        }
    }
}
