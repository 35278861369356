export default {
    namespaced: true,
    state: {},
    getters: {
        serverOperatingExpense: (state, getters, rootState, rootGetters) => switchCondition => {
            switch (switchCondition) {
            case 'cost':
                return Math.round(rootState.organizationInput.numberOfPrintServers * rootState.costCategories.monthlyOperatingExpensePerPrintServer * 12)
            case 'reduction':
                return Math.round(rootState.costCategories.operatingCostReduction)
            case 'savings':
                return Math.round(
                    (rootState.organizationInput.numberOfPrintServers * rootState.costCategories.monthlyOperatingExpensePerPrintServer * 12) *
            (rootState.costCategories.operatingCostReduction * 0.01)
                )
            default:
                // There should not be any other option
                return 123
            }
        },
        printRelatedServiceDeskCall: (state, getters, rootState, rootGetters) => switchCondition => {
            switch (switchCondition) {
            case 'cost':
                return Math.round(rootState.organizationInput.monthlyPrintRelatedServiceDeskCalls * rootState.costCategories.costPerPrintRelatedServiceDeskCall * 12)
            case 'reduction':
                return Math.round(rootState.costCategories.serviceDeskCallCostReduction)
            case 'savings':
                return Math.round(
                    (rootState.organizationInput.monthlyPrintRelatedServiceDeskCalls * rootState.costCategories.costPerPrintRelatedServiceDeskCall * 12) *
            (rootState.costCategories.serviceDeskCallCostReduction * 0.01)
                )
            default:
                // There should not be any other option
                return 234
            }
        },
        costOfAdministrationHours: (state, getters, rootState, rootGetters) => switchCondition => {
            switch (switchCondition) {
            case 'cost':
                return Math.round(rootState.costCategories.costPerPrinterAdminstrationHour * rootState.organizationInput.monthlyPrintManagementAdminHours * 12)
            case 'reduction':
                return Math.round(rootState.costCategories.administrationHourCostReduction)
            case 'savings':
                return Math.round(
                    (rootState.costCategories.costPerPrinterAdminstrationHour * rootState.organizationInput.monthlyPrintManagementAdminHours * 12) *
            (rootState.costCategories.administrationHourCostReduction * 0.01)
                )
            default:
                // There should not be any other option
                return 345
            }
        },
        totalCostOfPrintConsumables: (state, getters, rootState, rootGetters) => switchCondition => {
            // Helpers-------------
            const costOfColorPrinting = rootState.printConsumableInput.averagePagesPrintedPerMonth * (rootState.printConsumableInput.percentageOfPagesPrintedInColor * 0.01) * rootState.printConsumableInput.costPerPagePrintedInColor
            const costOfBlackAndWhitePrinting = rootState.printConsumableInput.averagePagesPrintedPerMonth * ((100 - rootState.printConsumableInput.percentageOfPagesPrintedInColor) * 0.01) * rootState.printConsumableInput.costPerPagePrintedInBW
            const totalCostOfPrinting = (costOfColorPrinting + costOfBlackAndWhitePrinting) * 12
            const pullPrintingSavings = rootState.purchaseOptions.numberOfPullLicenses > 0 ? totalCostOfPrinting * (rootState.purchaseOptions.costReductionWithPullPrinting * 0.01) : 0
            const consumableSavings = (totalCostOfPrinting - pullPrintingSavings) * (rootState.printConsumableInput.reductionOfPrintConsumablesWithPrinterLogic * 0.01)
            // --------------------
            switch (switchCondition) {
            case 'cost':
                return Math.round(totalCostOfPrinting)
            case 'reduction':
                return Math.round((pullPrintingSavings + consumableSavings) / (totalCostOfPrinting === 0 ? 1 : totalCostOfPrinting) * 100)
            case 'savings':
                return Math.round(pullPrintingSavings + consumableSavings)
            default:
                // There should not be any other option
                return 456
            }
        },
        totalCostOfWANTraffic: (state, getters, rootState, rootGetters) => switchCondition => {
            switch (switchCondition) {
            case 'cost':
                return Math.round(rootState.costCategories.totalMonthlyCostOfWanTrafficPerEmployee * rootState.organizationInput.numberOfEmployees)
            case 'reduction':
                return Math.round(rootState.costCategories.wanTrafficCostReduction)
            case 'savings':
                return Math.round(
                    (rootState.costCategories.totalMonthlyCostOfWanTrafficPerEmployee * rootState.organizationInput.numberOfEmployees) *
            (rootState.costCategories.wanTrafficCostReduction / 100)
                )
            default:
                // There should not be any other option
                return 567
            }
        },
        averageAnnualServerRefreshCost: (state, getters, rootState, rootGetters) => switchCondition => {
            switch (switchCondition) {
            case 'cost':
                return Math.round(rootState.costCategories.serverRefreshCostPerPrintServer * rootState.organizationInput.numberOfPrintServers / (rootState.organizationInput.averageServerRefreshPeriod === 0 ? 1 : rootState.organizationInput.averageServerRefreshPeriod))
            case 'reduction':
                return Math.round(rootState.costCategories.refreshCostReduction)
            case 'savings':
                return Math.round(
                    (rootState.costCategories.serverRefreshCostPerPrintServer * rootState.organizationInput.numberOfPrintServers / (rootState.organizationInput.averageServerRefreshPeriod === 0 ? 1 : rootState.organizationInput.averageServerRefreshPeriod)) *
            (rootState.costCategories.refreshCostReduction * 0.01)
                )
            default:
                // There should not be any other option
                return 678
            }
        },
        currentAnnualCostAndSavings: (state, getters, rootState, rootGetters) => switchCondition => {
            const annualServerOperatingExpense = rootState.organizationInput.numberOfPrintServers * rootState.costCategories.monthlyOperatingExpensePerPrintServer * 12
            const annualServerOperatingExpenseSavings = (rootState.organizationInput.numberOfPrintServers * rootState.costCategories.monthlyOperatingExpensePerPrintServer * 12) * (rootState.costCategories.operatingCostReduction * 0.01)

            const totalCurrentCost = (
                annualServerOperatingExpense +
        getters.printRelatedServiceDeskCall('cost') +
        getters.costOfAdministrationHours('cost') +
        getters.totalCostOfPrintConsumables('cost') +
        getters.averageAnnualServerRefreshCost('cost') +
        getters.totalCostOfWANTraffic('cost')
            )
            const totalPrinterLogicSavings = (
                annualServerOperatingExpenseSavings +
        getters.printRelatedServiceDeskCall('savings') +
        getters.costOfAdministrationHours('savings') +
        getters.totalCostOfPrintConsumables('savings') +
        getters.averageAnnualServerRefreshCost('savings') +
        getters.totalCostOfWANTraffic('savings')
            )
            switch (switchCondition) {
            case 'cost':
                return Math.round(totalCurrentCost)
            case 'reduction':
                return Math.round((totalPrinterLogicSavings / (totalCurrentCost === 0 ? 1 : totalCurrentCost)) * 100)
            case 'savings':
                return Math.round(totalPrinterLogicSavings)
            default:
                // There should not be any other option
                return 789
            }
        },
        costTableRows: (state, getters, rootState, rootGetters) => {
            return [
                { rowName: 'Server Operating Expense', currentCost: getters.serverOperatingExpense('cost'), costReduction: getters.serverOperatingExpense('reduction'), savings: getters.serverOperatingExpense('savings'), class: 'table-row-top-border' },
                { rowName: 'Print-Related Service Desk Call', currentCost: getters.printRelatedServiceDeskCall('cost'), costReduction: getters.printRelatedServiceDeskCall('reduction'), savings: getters.printRelatedServiceDeskCall('savings') },
                { rowName: 'Cost of Administration Hours', currentCost: getters.costOfAdministrationHours('cost'), costReduction: getters.costOfAdministrationHours('reduction'), savings: getters.costOfAdministrationHours('savings') },
                { rowName: 'Total Cost of Print Consumables', currentCost: getters.totalCostOfPrintConsumables('cost'), costReduction: getters.totalCostOfPrintConsumables('reduction'), savings: getters.totalCostOfPrintConsumables('savings') },
                { rowName: 'Total Cost of WAN Traffic', currentCost: getters.totalCostOfWANTraffic('cost'), costReduction: getters.totalCostOfWANTraffic('reduction'), savings: getters.totalCostOfWANTraffic('savings') },
                { rowName: 'Average Annual Server Refresh Cost', currentCost: getters.averageAnnualServerRefreshCost('cost'), costReduction: getters.averageAnnualServerRefreshCost('reduction'), savings: getters.averageAnnualServerRefreshCost('savings') },
                { rowName: 'Current Annual Costs and Savings', currentCost: getters.currentAnnualCostAndSavings('cost'), costReduction: getters.currentAnnualCostAndSavings('reduction'), savings: getters.currentAnnualCostAndSavings('savings'), class: 'table-row-top-border' }
            ]
        }
    },
    mutations: {},
    actions: {}
}
