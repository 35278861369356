<template>
  <div>
    <h2>Organization Information</h2>
    <p><strong><span class="red">*</span> Indicates a required field</strong></p>
    <form class="" method="post" @submit.prevent="submit">
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="company-name">Company Name</label>
                    <input class="form-control" type="text"
                      v-model="form.companyName"
                      name="companyName"
                      id="company-name"
                      :required="true">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="first-name">First Name</label>
                    <input class="form-control" type="text"
                      v-model="form.firstName"
                      name="firstName"
                      id="first-name"
                      :required="false">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="last-name">Last Name</label>
                    <input class="form-control" type="text"
                      v-model="form.lastName"
                      name="lastName"
                      id="last-name"
                      :required="false">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="email-address">Email Address</label>
                    <input class="form-control" type="text"
                      v-model="form.emailAddress"
                      name="emailAddress"
                      id="email-address"
                      :required="false">
                </div>
            </div>
            <p class="help-block">
              <span>by entering your email address, you agree to our <a href="https://www.printerlogic.com/privacy-policy/" target="_blank">Privacy Policy</a>.</span>
            </p>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="prepared-by">Prepared By</label>
                    <input class="form-control" type="text"
                    v-model="form.preparedBy"
                    name="preparedBy"
                    id="prepared-by"
                    :required="false">
                </div>
            </div>
        </div>
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="industry">Industry</label>
                    <select
                      class="form-control"
                      v-model="form.industry"
                      name="industry"
                      id="industry"
                      :required="true">
                      <option disabled value="" selected>Please select one</option>
                      <option v-for="industry in industryOptions"
                        :key="industry.title" :value="industry.id">
                        {{ industry.title }}
                      </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="currency">Currency</label>
                    <select
                      class="form-control"
                      v-model="form.currency"
                      name="currency"
                      id="currency"
                      :required="true">
                      <option disabled value="" selected>Please select one</option>
                      <option v-for="currency in currencyOptions"
                        :key="currency.title" :value="currency.id">
                        {{ currency.title }}
                      </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group required" v-if="showCurrencyRate">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="currency-rate">Currency Rate</label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.currencyRate"
                      name="currencyRate"
                      id="currency-rate"
                      :min="1"
                      :max="5"
                      :precision="2"
                      :required="true"
                      onfocus="this.select()"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="nav-buttons">
          <button type="submit" id="next-button">
            SAVE &amp; NEXT
            <span class="glyphicon glyphicon-arrow-right"></span>
          </button>
        </div>
    </form>
  </div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import VueNumeric from 'vue-numeric'
import industryOptions from '../objects/industries'
import { required, requiredIf, between } from 'vuelidate/lib/validators'

export default {
    name: 'OrganizationInformation',
    data: function () {
        return {
            // form input bindings by v-model
            form: {
                companyName: '',
                firstName: '',
                lastName: '',
                emailAddress: '',
                preparedBy: '',
                industry: '',
                currency: 'USD',
                currencyRate: 1.40
            },
            // select options for industry and currency
            industryOptions: industryOptions,
            currencyOptions: [
                { title: 'USD', id: 'USD' },
                { title: 'EUR', id: 'EUR' },
                { title: 'GBP', id: 'GBP' },
                { title: 'AUD (Reference Only)', id: 'AUD' }
            ]
        }
    }, /*
  props: [
    'updateMethod'
  ], */
    components: {
        VueNumeric
    },
    computed: {
        showCurrencyRate () {
            /* only shows the currency rate field if is AUD */
            return this.form.currency === 'AUD'
        }
    },
    mounted () {
    /* loads the form data from state if exist */
        this.form.companyName = this.$store.state.organizationInformation.companyName
        this.form.firstName = this.$store.state.organizationInformation.firstName
        this.form.lastName = this.$store.state.organizationInformation.lastName
        this.form.emailAddress = this.$store.state.organizationInformation.emailAddress
        this.form.preparedBy = this.$store.state.organizationInformation.preparedBy
        this.form.industry = this.$store.state.organizationInformation.industry
        this.form.currency = this.$store.state.organizationInformation.currency
        this.form.currencyRate = this.$store.state.organizationInformation.currencyRate
    },
    validations: {
        form: {
            companyName: { required },
            industry: { required },
            currency: { required },
            // optional validation. the currency rate must be between 1 and 5
            currencyRate: {
                required: requiredIf(function () {
                    return this.showCurrencyRate
                }),
                between: between(1, 5)
            }
        }
    },
    methods: {
        ...mapActions([
            'handleFormSubmit'
        ]),
        submit () {
            // call this.$v.form.$touch() to handle the form $dirty state manualy
            this.$v.form.$touch()
            // if an error exist we stop here and back to check
            if (this.$v.form.$error) return
            /*
        we going to call to updateorganizationInformation located on the application store
        and send all the values for every form field.
        The form object has all the fields values like fieldName -> value
       */
            Object.keys(this.form).forEach((field) => {
                const value = this.form[field]
                this.$store.commit('updateOrganizationInformation', { field, value })
            })
            /*
        call to the store handleFormSubmit method
        to change the route and update the application state
      */
            this.handleFormSubmit()
        }
    }
}
</script>
<style scoped>
  a {
    color: #591559;
  }
  .form-group.required .control-label:after, .red {
    color: red;
  }
  .nav-buttons {
    display: flex;
  }
  #next-button {
    margin-left:auto;
  }
  @media screen and (max-width: 326px) {
    .nav-buttons {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    #next-button {
      margin-left: 0em;
      margin-top: 1em;
    }
  }
  @media only screen and (max-width: 991px) {
    .col-md-8 + .col-md-4 { margin-top: 15px }
  }

  @media only screen and (min-width: 992px) and (max-width: 1230px) {
    .control-label {font-size: 1.05vw;}
  }
</style>
