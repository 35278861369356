<template>
  <div>
    <h2>Purchase Options</h2>
    <p><strong><span class="red">*</span> Indicates a required field</strong></p>
    <form class="" method="post" @submit.prevent="submit">
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="license-type">License Type</label>
                    <select
                      class="form-control"
                      v-model="form.licenseType"
                      name="licenseType"
                      id="license-type"
                      :required="true">
                      <option disabled value="" selected>Please select one</option>
                      <option v-for="licenseType in licenseTypeOptions"
                        :key="licenseType.title" :value="licenseType.id">
                        {{ licenseType.title }}
                      </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="num-licenses">Number of Licenses</label>
                    <vue-numeric
                        class="form-control"
                        v-model="form.numberOfLicenses"
                        name="numberOfLicenses"
                        id="num-licenses"
                        :min="0"
                        onfocus="this.select()"
                        :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="contract-term">Contract Term</label>
                    <select
                      class="form-control"
                      v-model="form.contractTerm"
                      name="contractTerm"
                      id="contract-term"
                      :required="true">
                      <option disabled value="" selected>Please select one</option>
                      <option v-for="contractTerm in contractTermOptions"
                        :key="contractTerm.title" :value="contractTerm.id">
                        {{ contractTerm.title }}
                      </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group required" >
            <div class="row">
                <div class="col-md-8">
                    <label class="control-label" for="num-pull-licenses">Number of Pull Printing Licenses</label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.numberOfPullLicenses"
                      name="numberOfPullLicenses"
                      id="num-pull-licenses"
                      :min="0"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
                <div class="col-md-4">
                    <label class="control-label" for="pull-print-cost-reduction">Cost Reduction with Pull Printing
                        <span
                            v-tooltip="{
                            content: 'Organizations implementing Pull Printing see a 10% to 20% reduction in total page volume.',
                            trigger: 'click hover'
                            }">
                            <font-awesome-icon icon="question-circle"/>
                        </span>
                    </label>
                    <vue-numeric
                        class="form-control"
                        v-model="form.costReductionWithPullPrinting"
                        name="costReductionWithPullPrinting"
                        id="pull-print-cost-reduction"
                        currencySymbolPosition="suffix"
                        currency="%"
                        :min="0"
                        :max="100"
                        onfocus="this.select()"
                        :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <!-- Right now mobile licenses are free for SaaS and VA Subscriptions -->
        <!-- git hash to reimplement the Mobile field 4cf723db135a040839b07fe98512d1dc0920e275 -->
        <div class="form-group required" v-if="isEMRLicense">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="num-emr-licenses">Number of EMR Licenses</label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.numberOfEMRLicenses"
                      name="numberOfEMRLicenses"
                      id="num-emr-licenses"
                      :min="0"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="nav-buttons">
          <button class="link-button" type="submit" id="back-button">
            <span class="glyphicon glyphicon-arrow-left"></span>
            SAVE &amp; BACK
          </button>
          <button type="submit" id="next-button">
            SAVE &amp; NEXT
            <span class="glyphicon glyphicon-arrow-right"></span>
          </button>
        </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VueNumeric from 'vue-numeric'
import constants from '../objects/constants'
import { required, requiredIf, minValue } from 'vuelidate/lib/validators'

export default {
    name: 'PrintConsumableInput',
    data: function () {
        return {
            // form input bindings by v-model
            form: {
                licenseType: '',
                numberOfLicenses: 0,
                contractTerm: 1,
                numberOfPullLicenses: 0,
                numberOfEMRLicenses: 0,
                costReductionWithPullPrinting: 10
            },
            licenseTypeOptions: [
                { title: 'Printerlogic | SaaS', id: constants.SAAS },
                { title: 'PrinterLogic | Virtual Appliance', id: constants.VIRTUAL }
            ],
            contractTermOptions: [
                { title: '1', id: '1' },
                { title: '3', id: '3' }
            ]
        }
    },
    props: [
        'updateMethod'
    ],
    components: {
        VueNumeric
    },
    computed: {
        ...mapState([
            'roiKey',
            'productKey'
        ]),
        isEMRLicense () {
            const isEMR = constants.isEMR.includes(this.$store.state.organizationInformation.industry)
            /* because Question component uses negative logic we need to change the store !isEMR to isEMR */
            /* 'na' is a valid product key? this option should be enabled if 'na' is the default value of productKey? */
            /* For reference Store.js line 292 */
            return isEMR
        }
    },
    mounted () {
    /* loads the form data from state if exist */
        this.form.licenseType = this.$store.state.purchaseOptions.licenseType
        this.form.numberOfLicenses = this.$store.state.purchaseOptions.numberOfLicenses
        this.form.contractTerm = this.$store.state.purchaseOptions.contractTerm
        this.form.numberOfPullLicenses = this.$store.state.purchaseOptions.numberOfPullLicenses
        this.form.numberOfEMRLicenses = this.$store.state.purchaseOptions.numberOfEMRLicenses
        this.form.costReductionWithPullPrinting = this.$store.state.purchaseOptions.costReductionWithPullPrinting
    },
    validations: {
        form: {
            licenseType: { required },
            numberOfLicenses: { required, minValue: minValue(0) },
            contractTerm: { required },
            numberOfPullLicenses: { required, minValue: minValue(0) },
            numberOfEMRLicenses: {
                required: requiredIf(function () {
                    return this.isEMRLicense
                }),
                minValue: minValue(0)
            },
            /* we should validate the costReductionWithPullPrinting?
         it seems like would be 10% or 20% only */
            costReductionWithPullPrinting: { required }
        }
    },
    methods: {
        ...mapActions([
            'handleFormSubmit'
        ]),
        submit () {
            // call this.$v.form.$touch() to handle the form $dirty state manualy
            this.$v.form.$touch()
            // if an error exist we stop here and back to check
            if (this.$v.form.$error) return
            /*
        we going to call to updateMethod located on the application store
        and send all the values for every form field.
        The form object has all the fields values like fieldName -> value
       */
            Object.keys(this.form).forEach((field) => {
                const value = this.form[field]
                this.$store.commit('updatePurchaseOptions', { field, value })
            })
            /*
        call to the store handleFormSubmit method
        to change the route and update the application state
      */
            this.handleFormSubmit()
        }
    }
}
</script>

<style>
  .form-group.required .control-label:after, .red {
      color: red;
  }
  .tooltip .tooltip-inner {
    border-radius: 4px;
  }
  .nav-buttons {
    display: flex;
  }
  #back-button {
    align-self: flex-start;
  }
  #next-button {
    margin-left:auto;
  }
  @media screen and (max-width: 326px) {
    .nav-buttons {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    #next-button {
      margin-left: 0em;
      margin-top: 1em;
    }
  }
</style>
