<template>
  <div>
    <h2>Print Consumable Input</h2>
    <p><strong><span class="red">*</span> Indicates a required field</strong></p>
    <form class="" method="post" @submit.prevent="submit">
      <div class="form-group">
          <div class="row">
              <div class="col-md-12">
                  <label class="control-label" for="average-pages">Average Pages Printed / Month</label>
                  <vue-numeric
                      class="form-control"
                      v-model="form.averagePagesPrintedPerMonth"
                      name="averagePagesPrintedPerMonth"
                      id="average-pages"
                      :min="0"
                      onfocus="this.select()"
                  ></vue-numeric>
              </div>
          </div>
      </div>
      <div class="form-group">
          <div class="row">
              <div class="col-md-12">
                  <label class="control-label" for="percent-color">Percentage of Pages Printed in Color</label>
                  <vue-numeric
                      class="form-control"
                      v-model="form.percentageOfPagesPrintedInColor"
                      name="percentageOfPagesPrintedInColor"
                      id="percent-color"
                      currencySymbolPosition="suffix"
                      currency="%"
                      :min="0"
                      :max="100"
                      onfocus="this.select()"
                  ></vue-numeric>
              </div>
          </div>
      </div>
      <div class="form-group">
          <div class="row">
              <div class="col-md-12">
                  <label class="control-label" for="bw-cost">Cost per Page Printed in B&amp;W</label>
                  <vue-numeric
                    class="form-control"
                    v-model="form.costPerPagePrintedInBW"
                    name="costPerPagePrintedInBW"
                    id="bw-cost"
                    :currency="currencySymbol"
                    :min="0"
                    :precision="4"
                    onfocus="this.select()"
                  ></vue-numeric>
              </div>
          </div>
      </div>
      <div class="form-group">
          <div class="row">
              <div class="col-md-12">
                  <label class="control-label" for="color-cost">Cost per Page Printed in Color</label>
                  <vue-numeric
                    class="form-control"
                    v-model="form.costPerPagePrintedInColor"
                    name="costPerPagePrintedInColor"
                    id="color-cost"
                    :currency="currencySymbol"
                    :min="0"
                    :precision="4"
                    onfocus="this.select()"
                  ></vue-numeric>
              </div>
          </div>
      </div>
      <div class="form-group">
          <div class="row">
              <div class="col-md-12">
                  <label class="control-label" for="consumables-reduction">Reduction of Print Consumables with PrinterLogic</label>
                  <vue-numeric
                    class="form-control"
                    v-model="form.reductionOfPrintConsumablesWithPrinterLogic"
                    name="reductionOfPrintConsumablesWithPrinterLogic"
                    id="consumables-reduction"
                    currencySymbolPosition="suffix"
                    currency="%"
                    :min="0"
                    :max="100"
                    onfocus="this.select()"
                  ></vue-numeric>
              </div>
          </div>
      </div>
      <div class="nav-buttons">
        <button class="link-button" type="submit" id="back-button">
          <span class="glyphicon glyphicon-arrow-left"></span>
          SAVE &amp; BACK
        </button>
        <button type="submit" id="next-button">
          SAVE &amp; NEXT
          <span class="glyphicon glyphicon-arrow-right"></span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VueNumeric from 'vue-numeric'
import { between, minValue } from 'vuelidate/lib/validators'
import constants from '../objects/constants'

export default {
    name: 'PrintConsumableInput',
    data: function () {
        return {
            // form input bindings by v-model
            form: {
                averagePagesPrintedPerMonth: 0,
                percentageOfPagesPrintedInColor: 0,
                costPerPagePrintedInBW: 0.05,
                costPerPagePrintedInColor: 0.12,
                reductionOfPrintConsumablesWithPrinterLogic: 20
            }
        }
    },
    components: {
        VueNumeric
    },
    computed: {
        ...mapState([
            'roiKey',
            'productKey'
        ]),
        /* Sets the currency symbol from state */
        currencySymbol () {
            return constants[this.$store.state.organizationInformation.currency]
        }
    },
    mounted () {
    /* loads the form data from state if exist */
        this.form.averagePagesPrintedPerMonth = this.$store.state.printConsumableInput.averagePagesPrintedPerMonth
        this.form.percentageOfPagesPrintedInColor = this.$store.state.printConsumableInput.percentageOfPagesPrintedInColor
        this.form.costPerPagePrintedInBW = this.$store.state.printConsumableInput.costPerPagePrintedInBW
        this.form.costPerPagePrintedInColor = this.$store.state.printConsumableInput.costPerPagePrintedInColor
        this.form.reductionOfPrintConsumablesWithPrinterLogic = this.$store.state.printConsumableInput.reductionOfPrintConsumablesWithPrinterLogic
    },
    validations: {
        form: {
            averagePagesPrintedPerMonth: { minLength: minValue(0) },
            percentageOfPagesPrintedInColor: { between: between(0, 100) },
            costPerPagePrintedInBW: { minLength: minValue(0) },
            costPerPagePrintedInColor: { minLength: minValue(0) },
            reductionOfPrintConsumablesWithPrinterLogic: { between: between(0, 100) }
        }
    },
    methods: {
        ...mapActions([
            'handleFormSubmit'
        ]),
        submit () {
            // call this.$v.form.$touch() to handle the form $dirty state manualy
            this.$v.form.$touch()
            // if an error exist we stop here and back to check
            if (this.$v.form.$error) return
            /*
        we going to call to updateMethod located on the application store
        and send all the values for every form field.
        The form object has all the fields values like fieldName -> value
       */
            Object.keys(this.form).forEach((field) => {
                const value = this.form[field]
                this.$store.commit('updatePrintConsumableInput', { field, value })
            })
            /*
        call to the store handleFormSubmit method
        to change the route and update the application state
      */
            this.handleFormSubmit()
        }
    }
}
</script>

<style>
  .form-group.required .control-label:after, .red {
      color: red;
  }
  .nav-buttons {
    display: flex;
  }
  #back-button {
    align-self: flex-start;
  }
  #next-button {
    margin-left:auto;
  }
  @media screen and (max-width: 326px) {
    .nav-buttons {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    #next-button {
      margin-left: 0em;
      margin-top: 1em;
    }
  }
</style>
