var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"progress-bar"}},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",style:({width: _vm.progressWidth}),attrs:{"role":"progressbar","aria-valuenow":"60","aria-valuemin":"0","aria-valuemax":"100"}})]),_vm._l((_vm.sidebarItems),function(point,index){return [_c('router-link',{key:point.href,class:{
        'active': point.href == _vm.currentRoute,
        'progress-point-link': true
      },attrs:{"to":{ name: point.href, params: { key: _vm.roiKey, productKey: _vm.productKey } }}},[_c('div',{class:{
          'progress-point': true
        },style:({
          left: ((index + 1) * (100 / _vm.sidebarItems.length)) - 10 + '%'
          })},[_vm._v(" "+_vm._s(index + 1)+" ")])])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }