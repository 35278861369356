<template>
  <div class="wrapper">
    <div :class="{'header': true, 'hide': inIframe}" class="display-flex">
      <font-awesome-icon icon="bars" @click="toggleMenu" class="pull-left hamburgerMenu" style="font-size: 2em;" />
      <h1 class="hidden-print roi-title">ROI Calculator</h1>
      <img id="logo" src="https://d1jqsvv1ta1uec.cloudfront.net/roicalculator/printerlogic_a_vasion_solution.png" alt="PrinterLogic">
    </div>
    <div class="old-state-message" v-if="oldStateMessage.length > 0">
      <p>{{ oldStateMessage }}</p>
      <button @click="messageRead">OK</button>
    </div>
    <div class="app-container">
      <div id="app" ref="app">
        <Sidebar />
        <MainView />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import MainView from '@/components/MainView'
import { mapState } from 'vuex'

export default {
    name: 'app',
    components: {
        Sidebar,
        MainView
    },
    data () {
        return {
            inIframe: window.top !== window
        }
    },
    computed: {
        ...mapState([
            'oldStateMessage'
        ])
    },
    mounted () {
        this.$eventBus.$on('toggleMenu', () => {
            this.toggleMenu()
        })
    },
    methods: {
        toggleMenu () {
            this.$refs.app.classList.toggle('sidebar-active')
        },
        messageRead () {
            // Commit to state
            this.$store.state.oldStateMessage = ''
        }
    }
}
</script>

<style>
#app {
  font-family: "Archivo", "Avenir", Helvetica, Arial, sans-serif;
  display: flex;
  flex: 1;
  overflow: scroll;
}

#logo {
  height: auto;
  width: auto;
  max-height: 3em;
  max-width: 15em;
  margin: 0em 1em;
}

.app-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  min-height: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.form-group.required .control-label:after {
  content:" *";
}

.hamburgerMenu {
  display: none;
}

.header {
  background-color: #591559;
  color: #fff;
  padding: 15px 15px;
}

.header h1 {
  font-family: "Archivo", "Avenir", Helvetica, Arial, sans-serif;
  margin: 0px;
  padding: 0px;
  font-size: 30px;
}

.link-button {
  display: inline-block;
  background: #f8f9f9;
  color: #4a4c4d;
  padding: 0.25em 0.75em;
  padding-top: 0.50em;
  text-decoration: none;
  margin-left: 5px;
  font-size: 14px;
  border-radius: 0.25em;
  border: none;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.link-button:hover {
  background: #e4e5e6;
}

.old-state-message {
  font-family: "Archivo", "Avenir", Helvetica, Arial, sans-serif;
  color: white;
  width: 50%;
  height: auto;
  padding: 1em;
  text-align: center;
  border-radius: 0.25em;
  z-index: 1000;
  background-color: #591559;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 6.5em;
  margin-left: auto;
  margin-right: auto;
}

.old-state-message p {
  padding: 0.25em;
}

.roi-title {
  display: block;
  float: left;
}

.symbol{
  position: absolute;
  top:30px;
  left: 15px;
  z-index: 1;
}

.symbol + input.form-control{
  padding-left: 30px;
}

.symbol.percentage{
  position: absolute;
  top: 31px;
  right: 35px;
  left: auto;
  z-index: 1;
}

.table-row-top-border {
  border-top: 2px solid #8a8a8a;
}

.table > tbody > tr > td{
  padding: 10px;
  vertical-align: middle;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
}

html,
body {
  height: 100%;
}

button, .results-back-button {
  display: inline-block;
  background: #FF5A00;
  color: #fff;
  padding: 0.25em 0.75em;
  padding-top: 0.50em;
  text-decoration: none;
  margin-left: 5px;
  font-size: 14px;
  border-radius: 0.25em;
  border: none;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

button:hover, .results-back-button:hover {
  color: #fff;
  background: #993600;
}

table {
  table-layout: auto;
  margin-top: 40px;
}

tr > *{
  width:16%;
  text-align: center;
}

tr > :first-child{
  width: 50%;
  text-align: left;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 991px){
  .col-md-4 {
    margin-top: 1em;
  }
}

@media screen and (max-width: 1300px) {
  .hamburgerMenu {
    display: block;
  }
  .roi-title {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .wrapper h1 {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 320px) {
  #logo {
    margin-top: 1.5em;
  }
  .display-flex {
    flex-direction: column;
  }
}

@media print {
  #app {
    overflow: hidden;
  }

  #progress-bar,.sidebar,.progress-point,.hide-for-print {
    display: none;
  }

  .licensing-cost-table, .roi-table, .cost-table, .net-savings-table {
    margin-top: -15px;
  }

  .table {
    margin-bottom: 0;
  }

  table {
    page-break-before: auto;
    page-break-inside: avoid;
    display: inline-table;
    margin: 25px 0 0 0;
  }
}
</style>
