<template>
  <div>
    <h2>Organization Input</h2>
    <p><strong><span class="red">*</span> Indicates a required field</strong></p>
    <form class="" method="post" @submit.prevent="submit">
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="num-employees">Number of Employees</label>
                    <vue-numeric
                        class="form-control"
                        v-model="form.numberOfEmployees"
                        name="numberOfEmployees"
                        id="num-employees"
                        :min="0"
                        onfocus="this.select()"
                        :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="num-printers">Number of Printers</label>
                    <vue-numeric
                        class="form-control"
                        v-model="form.numberOfPrinters"
                        name="numberOfPrinters"
                        id="num-printers"
                        currencySymbolPosition="suffix"
                        :min="0"
                        onfocus="this.select()"
                        :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="num-print-servers">Number of Print Servers</label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.numberOfPrintServers"
                      name="numberOfPrintServers"
                      id="num-print-servers"
                      :min="0"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="server-refresh-period">Average Server Refresh Period
                        <span
                            v-tooltip="{
                            content: 'Number of years between server replacements. Default is based on industry standard.',
                            trigger: 'click hover'
                        }">
                        <font-awesome-icon icon="question-circle"/>
                        </span>
                    </label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.averageServerRefreshPeriod"
                      name="averageServerRefreshPeriod"
                      id="server-refresh-period"
                      :min="1"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="service-desk-calls">Monthly Print Related Service Desk Calls
                        <span
                            v-tooltip="{
                            content: 'Companies average a single 30-minute print-related help desk call per employee annually.',
                            trigger: 'click hover'
                        }">
                        <font-awesome-icon icon="question-circle"/>
                        </span>
                    </label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.monthlyPrintRelatedServiceDeskCalls"
                      name="monthlyPrintRelatedServiceDeskCalls"
                      id="service-desk-calls"
                      :min="0"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="form-group required">
            <div class="row">
                <div class="col-md-12">
                    <label class="control-label" for="admin-hours">Monthly Print Management Admin Hours
                        <span
                            v-tooltip="{
                            content: 'Administrators average 1 hour per printer annually managing print related issues.',
                            trigger: 'click hover'
                        }">
                        <font-awesome-icon icon="question-circle"/>
                        </span>
                    </label>
                    <vue-numeric
                      class="form-control"
                      v-model="form.monthlyPrintManagementAdminHours"
                      name="monthlyPrintManagementAdminHours"
                      id="admin-hours"
                      :min="0"
                      onfocus="this.select()"
                      :required="true"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="nav-buttons">
          <button class="link-button" type="submit" id="back-button">
            <span class="glyphicon glyphicon-arrow-left"></span>
            SAVE &amp; BACK
          </button>
          <button type="submit" id="next-button">
            SAVE &amp; NEXT
            <span class="glyphicon glyphicon-arrow-right"></span>
          </button>
        </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VueNumeric from 'vue-numeric'
import { minValue } from 'vuelidate/lib/validators'

export default {
    name: 'PrintConsumableInput',
    data: function () {
        return {
            // form input bindings by v-model
            form: {
                numberOfEmployees: 0,
                numberOfPrinters: 0,
                numberOfPrintServers: 0,
                averageServerRefreshPeriod: 3,
                monthlyPrintRelatedServiceDeskCalls: 0,
                monthlyPrintManagementAdminHours: 0
            }
        }
    },
    components: {
        VueNumeric
    },
    computed: {
        ...mapState([
            'roiKey',
            'productKey'
        ])
    },
    mounted () {
    /* loads the form data from state if exist */
        this.form.numberOfEmployees = this.$store.state.organizationInput.numberOfEmployees
        this.form.numberOfPrinters = this.$store.state.organizationInput.numberOfPrinters
        this.form.numberOfPrintServers = this.$store.state.organizationInput.numberOfPrintServers
        this.form.averageServerRefreshPeriod = this.$store.state.organizationInput.averageServerRefreshPeriod
        this.form.monthlyPrintRelatedServiceDeskCalls = this.$store.state.organizationInput.monthlyPrintRelatedServiceDeskCalls
        this.form.monthlyPrintManagementAdminHours = this.$store.state.organizationInput.monthlyPrintManagementAdminHours
    },
    validations: {
        form: {
            numberOfEmployees: { minValue: minValue(0) },
            numberOfPrinters: { minValue: minValue(0) },
            numberOfPrintServers: { minValue: minValue(0) },
            averageServerRefreshPeriod: { minValue: minValue(1) },
            monthlyPrintRelatedServiceDeskCalls: { minValue: minValue(0) },
            monthlyPrintManagementAdminHours: { minValue: minValue(0) }
        }
    },
    methods: {
        ...mapActions([
            'handleFormSubmit'
        ]),
        submit () {
            // call this.$v.form.$touch() to handle the form $dirty state manualy
            this.$v.form.$touch()
            // if an error exist we stop here and back to check
            if (this.$v.form.$error) return
            /*
        we going to call to updateMethod located on the application store
        and send all the values for every form field.
        The form object has all the fields values like fieldName -> value
       */
            Object.keys(this.form).forEach((field) => {
                const value = this.form[field]
                this.$store.commit('updateOrganizationInput', { field, value })
            })
            /*
        call to the store handleFormSubmit method
        to change the route and update the application state
      */
            this.handleFormSubmit()
        }
    }
}
</script>

<style>
  .form-group.required .control-label:after, .red {
      color: red;
  }
  .tooltip .tooltip-inner {
    border-radius: 4px;
  }
</style>
