<template>
  <div class="main-content">
    <div class="key-info">
      <p class="display hidden-print">
        <strong>Return to or share this ROI report using this link: </strong>
        <small>
          <a :href="roiLink" ref="roiLink">{{roiLink}}</a>
        </small>
         (Unused links will expire in 6 months)
      </p>
      <button id="copy-url" class="hide-for-print pull-right" type="button" name="button" @click="copyText('roiLink')">
        <span class="glyphicon glyphicon-copy"></span>
      </button>
    </div>
    <div class="display">
      <ProgressBar />
      <router-view :key="$route.path"></router-view>
    </div>
  </div>
</template>

<script>
import ProgressBar from './ProgressBar'

export default {
    name: 'MainView',
    components: {
        ProgressBar
    },
    computed: {
        roiLink () {
            return `${window.location.origin}/${this.$store.state.roiKey}`
        }
    },
    methods: {
        copyText (containerId) {
            if (document.selection) {
                let range = document.body.createTextRange()
                range.moveToElementText(this.$refs.roiLink)
                range.select()
                document.execCommand('copy')
            } else if (window.getSelection) {
                let range = document.createRange()
                range.selectNode(this.$refs.roiLink)
                window.getSelection().removeAllRanges()
                window.getSelection().addRange(range)
                document.execCommand('copy')
            }
        }
    }
}
</script>

<style scoped>
  .main-content{
    order: 2;
    flex: 4;
    margin-bottom: 25px;
  }
  .display{
    max-width: 1000px;
    padding: 20px 30px;
    margin: 0px auto;
  }

  a {
    color: #591559;
    text-decoration: underline;
  }

  a:hover {
    color: black;
  }

  button {
    margin: 0px auto;
    margin-top: 0.9em;
    height: 2.5em;
    padding: 0.45em 1em;
    background-color: #E5E5E5;
  }

  .glyphicon-copy{
    color: black;
    font-size: 1.4em;
  }

   button:hover {
    background-color: rgba(102, 102, 102, 0.3);
  }

  .key-info{
    display:flex;
    -webkit-box-shadow: inset 0px -5px 15px 0px rgba(153,153,153, 0.3);
    -moz-box-shadow: inset 0px -5px 15px 0px rgba(153,153,153, 0.3);
    box-shadow: inset 0px -5px 15px 0px rgba(153,153,153, 0.3);
  }

  @media print {
    /* look at replacing this with something else */
    .display {
      max-width: 800px;
    }
  }
</style>
