<template>
  <router-link :to="{ name: item.href, params: { key: this.roiKey, productKey: this.productKey } }">
    <li @click="toggleMenu">
      <div class="info" :id="item.id">
        <h4>{{ item.title }}</h4>
        <p>{{ item.desc }}</p>
      </div>
      <div class="icon">
        <img class="img-responsive" v-if="item.iconPath" :src="item.iconPath">
      </div>
    </li>
  </router-link>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'SidebarItem',
    props: ['item'],
    computed: mapState([
        'roiKey',
        'productKey'
    ]),
    methods: {
        toggleMenu () {
            this.$eventBus.$emit('toggleMenu')
        }
    }
}
</script>

<style scoped>
  a {
    color: inherit;
  }

  li {
    position: relative;
    z-index: 2;
    height: 130px;
  }

  .info {
    width: calc(100% - 105px);
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  .icon{
    width: 70px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  img{
    margin: 0px auto;
  }
  .router-link-exact-active > li {
    background: #fff;
    width: calc(100% + 10px);
    border: none;
    z-index: 1;
    -webkit-box-shadow: -15px 5px 15px 0px rgba(153,153,153,1);
    -moz-box-shadow: -15px 5px 15px 0px rgba(153,153,153,1);
    box-shadow: -15px 5px 15px 0px rgba(153,153,153,1);
  }
  li {
    background: #e0e0e0;
  }
  .active + li{
    border-top: none;
    z-index: 0;
  }

  li:hover:not(.active){
    cursor: pointer;
  }
  li:hover:not(.active){
    background: #eee;
  }
  .active ~ li{
    cursor: default;
  }
  .active ~ li:hover{
    background: #e0e0e0;
    cursor: default;
  }

  @media only screen and (max-width: 1400px) {
    .info{
      width: calc(100% - 30px);
      z-index: 1;
    }
    .icon{
      opacity: .4;
      display: none;
    }
  }

  @media only screen and (max-width: 950px) {
    .info {
      z-index: inherit;
    }
  }
  @media only screen and (max-width: 500px) {
    h4{
      font-size: 16px;
    }
    .info{
      width: calc(100% - 30px);
      font-size: smaller;
    }
    .icon{
      display: none;
    }
  }
</style>
