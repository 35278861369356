<template>
  <div>
    <div class="cost-table">
      <table class="table table-striped" id="cost-table">
        <thead>
          <tr>
            <th
              v-for="header in headers"
              v-bind:key="header"
              class="text-right"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in costTableRows"
            :key="row.rowName"
            :class="row.class"
          >
            <td>{{ row.rowName }}</td>
            <td class="text-right">
              <vue-numeric
                :currency="currencySymbol"
                :minus="false"
                :read-only="true"
                :value="row.currentCost"
              ></vue-numeric>
            </td>
            <td class="text-right">
              {{ row.costReduction | percentage }}</td>
            <td class="text-right">
              <vue-numeric
                :currency="currencySymbol"
                :minus="false"
                :read-only="true"
                :value="row.savings"
              ></vue-numeric>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <table class="table table-striped" id="licensing-cost-table">
        <thead>
          <tr>
            <th class="pointer" @click="revertPrice">Costs</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-click-outside="onClickOutside">
          <tr>
            <td>
              Discount
            </td>
            <td  @click="enableEditState('discount')" class="text-right discount" :class="{ pointer: enableDiscountPointerClass }">
              <vue-numeric
                v-model="getDiscount"
                :read-only="!editableDiscountField"
                :currency="currencySymbol"
                :minus="true"
                @blur="onBlurHandler"
              ></vue-numeric>
            </td>
          </tr>
          <tr>
            <td>
              Annual Licensing Cost
            </td>
            <td @click="enableEditState('price')" class="text-right pointer">
              <vue-numeric
                v-model="annualLicensingCost"
                :currency="currencySymbol"
                :minus="false"
                :read-only="!editStates.price"
                @blur="onBlurHandler"
              ></vue-numeric>
            </td>
          </tr>
          <tr>
            <td>
              Professional Services
            </td>
            <td @click="enableEditState('services')" class="text-right pointer">
              <vue-numeric
                v-model="professionalServices"
                :currency="currencySymbol"
                :minus="false"
                :read-only="!editStates.services"
                @blur="onBlurHandler"
              ></vue-numeric>
            </td>
          </tr>
          <tr class="table-row-top-border">
            <td @click="enableEditState('years')">
              <select v-if="!typeYear" v-model="selectedYears" class="pointer">
                <option
                  v-for="option in this.yearContracts"
                  v-bind:key="option"
                  :value="option"
                  :selected="(option === contractLength)">
                  {{option}}
                </option>
              </select>
              <b>
                <vue-numeric
                  v-if="typeYear"
                  v-model="nthYear"
                  :minus="false"
                  :min="1"
                  :max="10"
                  :read-only="!editStates.years"
                  @blur="onBlurHandler"
                ></vue-numeric>
              </b>
              <b> Year Total Cost </b></td>
            <td class="text-right">
              <b>
                <vue-numeric
                  :read-only="true"
                  :currency="currencySymbol"
                  :value="totalLicensingCost() + this.professionalServices"
                ></vue-numeric>
              </b>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-striped" id="net-savings-table">
        <thead>
          <tr class="table-row-bottom-border">
            <th>Net Savings</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="savings in getNetSavingsRows" :key="savings.id">
            <td> {{ savings.rowName }} </td>
            <td class="text-right">
              <vue-numeric
                :currency="currencySymbol"
                :minus="false"
                :read-only="true"
                :value="savings.value"
              ></vue-numeric>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-striped" id="roi-table">
        <thead>
          <tr class="table-row-bottom-border">
            <th>Return On Investment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="roi in getROIRows" :key="roi.id">
            <td>{{ roi.rowName }}</td>
            <td class="text-right">{{ roi.value | percentage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import filters from '../filters'
import { mapState, mapGetters, mapActions } from 'vuex'
import VueNumeric from 'vue-numeric'
import vClickOutside from 'v-click-outside'
import constants from '../objects/constants'

export default {
    name: 'ResultTables',
    mixins: [filters],
    components: {
        VueNumeric
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        typeYear: { type: Boolean, default: true }
    },
    mounted () {
    // This is where the call to salesforce starts
        this.getPricingInfo()
    },
    data () {
        return {
            discount: 0,
            annualLicensingCost: 0,
            currency: '',
            professionalServices: 0,
            selectedYears: 1,
            editStates: {
                discount: false,
                price: false,
                services: false,
                years: false
            },
            yearContracts: [1, 3],
            numberTranslations: { 1: 'First', 2: 'Second', 3: 'Third', 4: 'Fourth', 5: 'Fifth', 6: 'Sixth', 7: 'Seventh', 8: 'Eighth', 9: 'Ninth', 10: 'Tenth' },
            nthYear: 3,
            headers: [ 'Cost Categories', 'Current Annual Costs', 'Cost Reduction Via PrinterLogic', 'PrinterLogic Savings' ]
        }
    },
    computed: {
        ...mapState([
            'contractLength',
            'organizationInformation',
            'priceOverride',
            'pricingInfo',
            'purchaseOptions'
        ]),
        ...mapGetters({
            costTableRows: 'resultTables/costTableRows'
        }),
        editableDiscountField () {
            return (this.getYearState() === 2 || this.getYearState() === 3) && this.editStates.discount
        },
        enableDiscountPointerClass () {
            return (this.getYearState() === 2 || this.getYearState() === 3)
        },
        getDiscount: {
            get () {
                return this.getYearState() === 1 ? 0 : this.discount
            },
            set (discount) {
                if (discount >= 0) {
                    this.discount = discount
                }
            }
        },
        getROIRows () {
            return [
                { rowName: 'First Year ROI', value: this.getROI(1), id: 1 },
                { rowName: this.getYearText() + ' Year ROI', value: this.getROI(this.typeYear ? this.nthYear : 3), id: 2 }
            ]
        },
        getNetSavingsRows () {
            return [
                { rowName: 'First Year Net Savings', value: this.getNetSavings(1), id: 1 },
                { rowName: this.getYearText() + ' Year Net Savings', value: this.getNetSavings(this.typeYear ? this.nthYear : 3), id: 2 }
            ]
        },
        currencySymbol () {
            return constants[this.$store.state.organizationInformation.currency]
        }
    },
    watch: {
        discount () {
            this.emitEmail()
        },
        annualLicensingCost () {
            this.emitEmail()
        },
        pricingInfo () {
            if (this.annualLicensingCost === 0 || this.currency !== this.organizationInformation.currency) {
                this.initializeData()
                this.currency = this.organizationInformation.currency
            }
        },
        professionalServices () {
            this.emitEmail()
        },
        selectedYears () {
            this.emitEmail()
        },
        nthYear () {
            this.emitEmail()
        },
        typeYear () {
            this.emitEmail()
        }
    },
    methods: {
        ...mapActions([
            'getPricingInfo',
            'saveState'
        ]),
        // Helpers-----------------
        doNotDivideBy0 (number) {
            return number === 0 ? 1 : number
        },
        emitEmail () {
            const year = this.typeYear ? this.nthYear : this.selectedYears
            const costs = { discount: this.getYearState() === 1 ? 0 : Math.round(this.discount), annualLicensingCost: Math.round(this.annualLicensingCost), professionalServices: Math.round(this.professionalServices) }
            costs[this.numberTranslations[year].toLowerCase() + 'YearTotalCost'] = this.totalLicensingCost() + Math.round(this.professionalServices)
            this.$emit('costs', costs)

            const savings = { firstYearNetSavings: this.getNetSavings(1) }
            savings[this.getYearText().toLowerCase() + 'YearNetSavings'] = this.getNetSavings(this.typeYear ? this.nthYear : 3)
            this.$emit('netSavings', savings)

            const roi = { firstYearROI: this.getROI(1) }
            roi[this.getYearText().toLowerCase() + 'YearROI'] = this.getROI(this.typeYear ? this.nthYear : 3)
            this.$emit('returnOnInvestment', roi)
        },
        enableEditState (override) {
            for (let key in this.editStates) {
                if (override === key) {
                    if (override === 'discount' && this.getYearState() === 1) {
                        return
                    }
                    this.editStates[override] = true
                }
            }
        },
        getYearState () {
            // 1 = one year 2 = nth year 3 = three year
            if (this.typeYear) {
                return 2
            } else { return Number(this.selectedYears) }
        },
        getYearText () {
            return this.numberTranslations[this.typeYear ? this.nthYear : 3]
        },
        initialDiscount () {
            let discount = this.pricingInfo.oneYearTotalCost * 3 - this.pricingInfo.threeYearTotalCost
            const rate = (this.organizationInformation.currency === 'AUD') ? this.organizationInformation.currencyRate : 1
            discount *= rate
            return discount
        },
        initializeData () {
            // ORDER MATTERS HERE
            this.annualLicensingCost = this.pricingInfo.oneYearTotalCost
            // change annualLicensingCost if currency is AUD
            this.annualLicensingCost *= this.organizationInformation.currency === 'AUD' ? this.organizationInformation.currencyRate : 1
            this.selectedYears = this.purchaseOptions.contractTerm
            // Set up the intital discount
            this.discount = this.initialDiscount()
            // Send initial values to chart
            this.oneYearLicensingCost()
            this.threeYearLicensingCost()
            this.currentCostArray()
            // Set up email
            this.emitEmail()
        },
        lastRowOfCostCategories (condition) {
            switch (condition) {
            case 'cost': return this.costTableRows[this.costTableRows.length - 1].currentCost
            case 'savings': return this.costTableRows[this.costTableRows.length - 1].savings
            case 'reduction': return this.costTableRows[this.costTableRows.length - 1].reduction
            default: return this.costTableRows[this.costTableRows.length - 1].savings
            }
        },
        onBlurHandler (e) {
            this.saveState(this.$router.currentRoute.params.key)
            for (let key in this.editStates) {
                this.editStates[key] = false
            }
        },
        onClickOutside (event) {
            this.onBlurHandler(event)
        },
        reducedAnnualCosts () {
            return this.lastRowOfCostCategories('cost') - this.lastRowOfCostCategories('savings')
        },
        // End of Helpers--------------------------------
        // LicensingCost calculations--------------------
        totalLicensingCost (year) {
            const switchCondition = !year ? this.getYearState() : year
            switch (switchCondition) {
            // We need to update the others of a change but only return the switch condition value
            case 1:
                if (this.typeYear) {
                    this.nthYearLicensingCost()
                } else {
                    this.threeYearLicensingCost()
                }
                return this.oneYearLicensingCost()
            case 2: this.oneYearLicensingCost(); return this.nthYearLicensingCost()
            case 3: this.oneYearLicensingCost(); return this.threeYearLicensingCost()
            default: return this.oneYearLicensingCost()
            }
        },
        /*
     * professional services only needs to be added on the first year on all the Licensing Costs functions
     * it is added to the Year Total Cost value on the template
     */
        oneYearLicensingCost () {
            const array = new Array(4).fill(0).map((num, index) => index * (this.reducedAnnualCosts() + this.annualLicensingCost) + (index === 1 ? this.professionalServices : 0))
            this.$emit('oneYearSubscriptionArray', array)
            return Math.round(this.annualLicensingCost)
        },
        threeYearLicensingCost () {
            const cost = (this.annualLicensingCost * 3) + (this.discount * -1)
            const array = new Array(4).fill(0).map((num, index) => index * this.reducedAnnualCosts() + (index === 0 ? 0 : cost) + (index === 1 ? this.professionalServices : 0))
            this.$emit('threeYearSubscriptionArray', { array: array, year: 3 })
            return Math.round(cost)
        },
        nthYearLicensingCost () {
            const cost = (this.annualLicensingCost * this.nthYear) + (this.discount * -1)
            const array = new Array(this.nthYear + 1).fill(0).map((num, index) => index * this.reducedAnnualCosts() + (index === 0 ? 0 : cost) + (index === 1 ? this.professionalServices : 0))
            this.$emit('nthYearSubscriptionArray', { array: array, year: this.nthYear })
            return Math.round(cost)
        },
        currentCostArray () {
            const array = new Array(4).fill(0).map((num, index) => (index) * this.lastRowOfCostCategories('cost'))
            this.$emit('currentCostArray', array)
        },
        // --------------------------------
        // ROI Calculations----------------
        getROI (year) {
            const timesYear = this.getYearState() === 1 ? year : 1
            const yearSavings = this.getNetSavings(year)
            const yearPercentage = Math.round(
                (
                    yearSavings /
          (this.doNotDivideBy0(this.totalLicensingCost(this.getYearState()) * timesYear) + this.professionalServices)
                ) * 100)
            return yearPercentage
        },
        // --------------------------------
        // Net Saving Calculations---------
        getNetSavings (year) {
            const timesYear = this.getYearState() === 1 ? year : 1
            return Math.round(this.lastRowOfCostCategories('savings') * year - (this.totalLicensingCost(this.getYearState()) * timesYear + this.professionalServices))
        },
        // -------------------------------
        revertPrice () {
            this.professionalServices = 0
            this.annualLicensingCost = this.pricingInfo.oneYearTotalCost
            // change annualLicensingCost if currency is AUD
            this.annualLicensingCost *= this.organizationInformation.currency === 'AUD' ? this.organizationInformation.currencyRate : 1
            this.selectedYears = this.purchaseOptions.contractTerm
            this.discount = this.initialDiscount()
            this.saveState(this.$router.currentRoute.params.key)
        }
    },
    created () {
        this.initializeData()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.pointer {
  cursor: pointer;
}
.discount {
  color: red;
}
</style>
