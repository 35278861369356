<template>
  <div id="progress-bar">
    <div class="progress">
      <div
      class="progress-bar"
      role="progressbar"
      aria-valuenow="60"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="{width: progressWidth}"></div>
    </div>
    <template v-for="(point, index) in sidebarItems">
      <router-link
        :to="{ name: point.href, params: { key: roiKey, productKey: productKey } }"
        :class="{
          'active': point.href == currentRoute,
          'progress-point-link': true
        }"
        :key="point.href">
        <div
          :style="{
            left: ((index + 1) * (100 / sidebarItems.length)) - 10 + '%'
            }"
          :class="{
            'progress-point': true
          }">
          {{ index + 1 }}
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
import {
    mapState
} from 'vuex'

export default {
    name: 'ProgressBar',
    computed: {
        ...mapState([
            'currentRoute',
            'roiKey',
            'productKey',
            'sidebarItems'
        ]),
        progressWidth () {
            const routeIndex = this.sidebarItems.findIndex(route => route.href === this.currentRoute)
            const width = ((routeIndex + 1) * (100 / this.sidebarItems.length)) - 10
            return `${width}%`
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #progress-bar{
    position: relative;
    margin: 30px;
    height: 10px;
    border-radius: 20px;
  }

  #progress-bar .progress{
    color: #591559;
    height: 100%;
    width: 100%;
  }

  .progress-bar{
    background-color: #6666;
  }

  .progress-point{
    position: absolute;
    width: 40px;
    height: 40px;
    top: -15px;
    border-radius: 50%;
    background-color: #666;
    color: #fff;
    text-align: center;
    line-height: 33px;
    font-size: 20px;
    border: #fff solid 3px;
    -webkit-box-shadow: inset 0px 3px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: inset 0px 3px 10px 0px rgba(0,0,0,0.1);
    box-shadow: inset 0px 3px 10px 0px rgba(0,0,0,0.1);
  }
  .progress-point-link.active ~ .progress-point-link > .progress-point {
    background-color: #e0e0e0;
  }
  @media only screen and (max-width: 500px) {
    #progress-bar{
      display: none;
    }
  }
</style>
