<template>
  <div class="sidebar">
    <div class="content">
      <ul>
        <SidebarItem
        v-for="(item, index) in sidebarItems"
        v-bind:item="item"
        :key="index" />
      </ul>
    </div>
  </div>
</template>

<script>
import {
    mapState
} from 'vuex'
import SidebarItem from './SidebarItem'

export default {
    name: 'Sidebar',
    components: {
        SidebarItem
    },
    computed: {
        ...mapState([
            'sidebarItems'
        ])
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .sidebar {
    order: 1;
    flex: 2;
    width: 400px;
    max-width: 400px;
    border-right: solid 10px #666;
    background: #efefef;
  }

  ul {
    padding: 0px;
  }

  li {
    list-style: none;
    padding: 5px;
    border: solid 1px #999;
    border-right: none;
    border-left: none;
  }

  li + li {
    border-top: none;
  }

  .content {
    background: #fff;
  }

  @media only screen and (max-width: 1300px) {
    .sidebar {
      position: absolute;
      overflow: hidden;
      width: 400px;
      min-height:100%;
      transform: translateX(-100%);
      z-index: 100;
      -webkit-box-shadow:  10px 0px 10px 0px rgba(153,153,153, 0.3);
      -moz-box-shadow:  10px 0px 10px 0px rgba(153,153,153, 0.3);
      box-shadow:  10px 0px 10px 0px rgba(153,153,153, 0.3);
      -webkit-transition: transform ease-out .33s;
      -moz-transition: transform ease-out .33s;
      -o-transition: transform ease-out .33s;
      transition: transform ease-out .33s;

      .sidebar-active & {
        transform: translateX(0%)
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .sidebar {
      width: 230px;
    }
  }
</style>
