import Vue from 'vue'
import Router from 'vue-router'

import OrganizationInformation from '@/components/OrganizationInformation'
import PrintConsumableInput from '@/components/PrintConsumableInput'
import OrganizationInput from '@/components/OrganizationInput'
import PurchaseOptions from '@/components/PurchaseOptions'
import CostCategories from '@/components/CostCategories'
import Results from '@/components/Results'

Vue.use(Router)

const BASE_URL = '/product/:productKey?/state/:key?'

export default new Router({
    mode: 'history',
    routes: [
        {
            path: `${BASE_URL}/organization-info`,
            name: 'OrganizationInfo',
            component: OrganizationInformation
        },
        {
            path: `${BASE_URL}/print-input`,
            name: 'PrintInput',
            component: PrintConsumableInput
        },
        {
            path: `${BASE_URL}/organization-input`,
            name: 'OrganizationInput',
            component: OrganizationInput
        },
        {
            path: `${BASE_URL}/purchase-options`,
            name: 'PurchaseOptions',
            component: PurchaseOptions
        },
        {
            path: `${BASE_URL}/cost-categories`,
            name: 'CostCategories',
            component: CostCategories
        },
        {
            path: `${BASE_URL}/results`,
            name: 'Results',
            component: Results
        },
        {
            path: '/:productKey(pl|pc|na)',
            redirect: { name: 'OrganizationInfo' }
        },
        {
            path: '/:key?',
            redirect: { name: 'OrganizationInfo' }
        },
        {
            path: '/:productKey?/:key?',
            redirect: { name: 'OrganizationInfo' }
        }
    ]
})
