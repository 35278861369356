import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/Store'
import VTooltip from 'v-tooltip'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuestionCircle, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuelidate from 'vuelidate'

library.add(faQuestionCircle, faBars)

Vue.use(VTooltip)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Vuelidate)

router.afterEach(async () => {
    store.commit('setCurrentRoute', router.currentRoute.name)
    store.commit('setProductKey', router.currentRoute.params.productKey)
    if (router.currentRoute.params.key && store.state.roiKey === null) {
        try {
            // fetch the state
            await store.dispatch('getState', router.currentRoute.params.key)
        } catch (err) {
            console.log(err)
        }
    }
    if (store.state.reportingData.createdDate === 0) {
        store.commit('setCreatedDate')
    } else {
        store.commit('updateModifiedDate')
        store.commit('updateNumTimesModified')
    }
    if (router.currentRoute.name === 'Results') {
        store.commit('updateResultsLastAccessedDate')
        store.commit('updateNumTimesResultsAccessed')
    }
    try {
    // save state
        await store.dispatch('saveState', router.currentRoute.params.key)
    } catch (err) {
        console.log(err)
    }
})

Vue.prototype.$eventBus = new Vue()

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
