export default [
    {
        title: 'Organization Information',
        desc: 'Information regarding you and your company\'s information so that you can retrieve this return on investment estimate later.',
        iconPath: 'https://d1jqsvv1ta1uec.cloudfront.net/roicalculator/building.png',
        href: 'OrganizationInfo',
        id: 'side-bar-org-info'
    },
    {
        title: 'Print Consumables',
        desc: 'A brief collection of information on what consumables you currently use for the purpose of calculating the possible reduction.',
        iconPath: 'https://d1jqsvv1ta1uec.cloudfront.net/roicalculator/printer.png',
        href: 'PrintInput',
        id: 'side-bar-print-consumable'
    },
    {
        title: 'Organization',
        desc: 'Collection of information on how your organization uses and maintains consumables and print infrastructure.',
        iconPath: 'https://d1jqsvv1ta1uec.cloudfront.net/roicalculator/clipboard.png',
        id: 'side-bar-organization',
        href: 'OrganizationInput'
    },
    {
        title: 'Purchase Options',
        desc: 'Options and modules.',
        iconPath: 'https://d1jqsvv1ta1uec.cloudfront.net/roicalculator/briefcase.png',
        id: 'side-bar-purchase-opts',
        href: 'PurchaseOptions'
    },
    {
        title: 'Cost Categories',
        desc: 'How much are you spending and what can we save you?',
        iconPath: 'https://d1jqsvv1ta1uec.cloudfront.net/roicalculator/money.png',
        id: 'side-bar-cost-cat',
        href: 'CostCategories'
    },
    {
        title: 'Results',
        desc: 'Display your current spend vs. estimated spend with PrinterLogic.',
        iconPath: 'https://d1jqsvv1ta1uec.cloudfront.net/roicalculator/piggy-bank.png',
        id: 'side-bar-results',
        href: 'Results'
    }
]
