const industries = [
    'Business Services: Advertising',
    'Construction: Commercial',
    'Construction: Residential',
    'Consulting',
    'Consulting: Accounting',
    'Consulting: Employment Agency',
    'Education: Community College',
    'Education: K-12',
    'Education: University',
    'Electronics',
    'Engineering',
    'Entertainment',
    'Environmental',
    'Finance: Insurance Services',
    'Finance: International Banking',
    'Finance: Investment Banking',
    'Finance: Lending',
    'Finance: Regional Banking',
    'Finance: Services',
    'Food & Beverage',
    'Government: City / County',
    'Government: Federal / Country',
    'Government: State / Province',
    'Healthcare: Care Clinics',
    'Healthcare: Hospitals',
    'Hospitality',
    'Legal',
    'Logistics',
    'Manufacturing',
    'Manufacturing: Agriculture',
    'Manufacturing: Biotechnology',
    'Manufacturing: Bottling',
    'Manufacturing: Chemical',
    'Manufacturing: Foundry',
    'Manufacturing: Infrastructure',
    'Manufacturing: Machinery',
    'Media',
    'Mining: Energy',
    'Mining: Metals',
    'Mining: Supplier',
    'Non Profit: Ecclesiastical',
    'Non Profit: Health Research',
    'Real Estate',
    'Recreation',
    'Reseller: MSP',
    'Reseller: VAR',
    'Retail',
    'Retail: Dealerships',
    'Retail: Household Goods',
    'Retail: Jewelry',
    'Retail: Wholesale',
    'Technology: Networking',
    'Technology: Security',
    'Technology: Software',
    'Technology: Telecommunications',
    'Transportation: Air',
    'Transportation: Rail',
    'Transportation: Truck',
    'Transportation: Water',
    'Utilities',
    'Waste Services'
]

function formatId (str) {
    return str.replace(/[^A-Z0-9]/ig, '').toUpperCase()
}

function genIndustryObj () {
    return industries.map(industry => {
        return { title: industry, id: formatId(industry) }
    })
}

export default genIndustryObj()
