import Vue from 'vue'
import Vuex from 'vuex'
import APIConfig from '../apiconfig'
import sidebarItems from '../objects/sidebarItems'
import omit from 'lodash.omit'
import axios from 'axios'
import qs from 'qs'
import router from '../router'
import constants from '../objects/constants'

import resultTables from './modules/resultTables'

axios.defaults.baseURL = `${APIConfig.protocol}://${APIConfig.hostname}${APIConfig.root}`

Vue.use(Vuex)

// The getters need to be exported seperately in order for the unit tests to work.

export const getters = {
    currentRouteObject (state, getters) {
        return state.sidebarItems[state.sidebarItems.findIndex(route => route.href === state.currentRoute)]
    },
    nextRoute (state, getters) {
        return state.sidebarItems[state.sidebarItems.findIndex(route => route.href === state.currentRoute) + 1]
    },
    prevRoute (state, getters) {
        return state.sidebarItems[state.sidebarItems.findIndex(route => route.href === state.currentRoute) - 1]
    }
}

export default new Vuex.Store({
    modules: {
        resultTables
    },
    // If the state is updated, getState function needs to be updated as well
    state: {
        sidebarItems: sidebarItems,
        organizationInformation: {
            companyName: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            preparedBy: '',
            industry: '',
            currency: 'USD',
            currencyRate: 1.40
        },
        printConsumableInput: {
            averagePagesPrintedPerMonth: 0,
            percentageOfPagesPrintedInColor: 0,
            costPerPagePrintedInBW: 0.05,
            costPerPagePrintedInColor: 0.12,
            reductionOfPrintConsumablesWithPrinterLogic: 20
        },
        organizationInput: {
            numberOfEmployees: 0,
            numberOfPrinters: 0,
            numberOfPrintServers: 0,
            averageServerRefreshPeriod: 3,
            monthlyPrintRelatedServiceDeskCalls: 0,
            monthlyPrintManagementAdminHours: 0
        },
        purchaseOptions: {
            licenseType: '',
            numberOfLicenses: 0,
            contractTerm: 1,
            numberOfPullLicenses: 0,
            numberOfEMRLicenses: 0,
            costReductionWithPullPrinting: 10
        },
        costCategories: {
            serverRefreshCostPerPrintServer: 2000,
            refreshCostReduction: 90,
            monthlyOperatingExpensePerPrintServer: 170,
            operatingCostReduction: 90,
            totalMonthlyCostOfWanTrafficPerEmployee: 1.5,
            wanTrafficCostReduction: 90,
            costPerPrintRelatedServiceDeskCall: 50,
            serviceDeskCallCostReduction: 50,
            costPerPrinterAdminstrationHour: 30,
            administrationHourCostReduction: 60
        },
        reportingData: {
            createdDate: 0,
            modifiedDate: 0,
            resultsLastAccessedDate: 0,
            numTimesResultsAccessed: 0,
            numTimesModified: 0
        },
        pricingInfo: {
            threeYearTotalCost: 0,
            oneYearTotalCost: 0
        },
        currentRoute: router.currentRoute.name || sidebarItems[0].href,
        productKey: 'na',
        roiKey: null,
        calculatingPrice: false,
        oldStateMessage: ''
    },
    getters,
    mutations: {
        updateOrganizationInformation (state, { field, value }) {
            Object.assign(state.organizationInformation, {
                [field]: value
            })
        },
        updatePrintConsumableInput (state, { field, value }) {
            Object.assign(state.printConsumableInput, {
                [field]: value
            })
        },
        updateOrganizationInput (state, { field, value }) {
            Object.assign(state.organizationInput, {
                [field]: value
            })
        },
        updatePurchaseOptions (state, { field, value }) {
            Object.assign(state.purchaseOptions, {
                [field]: value
            })
        },
        updateCostCategories (state, { field, value }) {
            Object.assign(state.costCategories, {
                [field]: value
            })
        },
        setPricingInfo (state, pricingInfo) {
            state.pricingInfo = pricingInfo
        },
        setCurrentRoute (state, routeName) {
            state.currentRoute = routeName
        },
        setProductKey (state, productKey = null) {
            state.productKey = productKey || state.productKey
        },
        setRoiKey (state, key) {
            state.roiKey = key
        },
        setCreatedDate (state) {
            state.reportingData.createdDate = new Date().toISOString()
        },
        startPriceCalculation (state) {
            state.calculatingPrice = true
        },
        endPriceCalculation (state) {
            state.calculatingPrice = false
        },
        updateModifiedDate (state) {
            state.reportingData.modifiedDate = new Date().toISOString()
        },
        updateNumTimesResultsAccessed (state) {
            state.reportingData.numTimesResultsAccessed += 1
        },
        updateNumTimesModified (state) {
            state.reportingData.numTimesModified += 1
        },
        updateResultsLastAccessedDate (state) {
            state.reportingData.resultsLastAccessedDate = new Date().toISOString()
        },
        updateState (state, updatedState) {
            Object.assign(state, updatedState)
        }
    },
    actions: {
        handleFormSubmit ({ state, getters }) {
            let inputFields = document.getElementsByClassName('form-control')
            let lastElement = inputFields[inputFields.length - 1]
            let isNextButton = document.activeElement.id ? document.activeElement.id === 'next-button' : false
            let isBackButton = document.activeElement.id ? document.activeElement.id === 'back-button' : false

            if (document.activeElement === lastElement || (isNextButton || isBackButton)) {
                router.push({
                    name: isNextButton ? getters.nextRoute.href : getters.prevRoute.href,
                    params: { key: state.roiKey, productKey: state.productKey }
                })
            }
        },
        async saveState ({ commit, state }) {
            try {
                const blacklist = [
                    'pricingInfo',
                    'sidebarItems',
                    'questionGroups',
                    'calculatingPrice'
                ]
                const blacklistedState = omit(state, blacklist)
                const { data: { id } } = await axios.post('/state', blacklistedState)
                commit('setRoiKey', id)
                if (!router.currentRoute.params.key) {
                    router.replace({ name: state.currentRoute, params: { key: state.roiKey, productKey: state.productKey } })
                }
            } catch (err) {
                console.log(err)
            }
        },
        async getState ({ commit, state, dispatch }, key) {
            try {
                const { data } = await axios(`/state/${key}`)
                // This throws an error if an old state is detected and a new ROI key is made
                if (data.state.orgInfoData) {
                    state.oldStateMessage = 'We\'ve made some improvements! The ROI link you have clicked on is no longer valid.  Simply click \'OK\' to begin filling out the form with a newly generated link.'
                    return
                }
                commit('updateState', data.state)
                commit('setRoiKey', data._id)
                router.replace({ name: state.currentRoute, params: { key: state.roiKey, productKey: state.productKey } })
                dispatch('getPricingInfo')
            } catch (err) {
                console.log(err)
                // If getState ran then there was an ROI key entered
                state.oldStateMessage = 'We are sorry for the inconvenience, but that ROI link is expired! Simply click \'OK\' to begin filling out the form and to view the newly generated link.'
            }
        },
        async getPricingInfo ({ commit, state, getters }) {
            try {
                state.contractLength = state.purchaseOptions.contractTerm
                const pricingReqOpts = {
                    /*
           * ROI Server is looking for these specific variables
           * numCoreLicenses, numPullLicenses, numMobileLicenses, numEMRLicenses
           */
                    numCoreLicenses: state.purchaseOptions.numberOfLicenses,
                    numPullLicenses: state.purchaseOptions.numberOfPullLicenses,
                    numMobileLicenses: 0,
                    numEMRLicenses: state.purchaseOptions.numberOfEMRLicenses,
                    product: state.purchaseOptions.licenseType === constants.VIRTUAL ? 'pl' : 'pc',
                    subType: 'sub',
                    isEducation: constants.isEducation.includes(state.organizationInformation.industry),
                    currencyCode: state.organizationInformation.currency,
                    maintenance: 0
                }
                if (pricingReqOpts.numCoreLicenses === 0 && pricingReqOpts.numPullLicenses === 0 && pricingReqOpts.numEMRLicenses === 0) {
                    return
                }
                commit('startPriceCalculation')
                const { data: { subtotal: licensingCost } } = await axios(`/price-estimate?${qs.stringify(pricingReqOpts)}`)
                commit('endPriceCalculation')
                const [oneYearTotalCost, threeYearTotalCost] = licensingCost
                commit('setPricingInfo', { oneYearTotalCost, threeYearTotalCost })
            } catch (err) {
                commit('endPriceCalculation')
                console.log(err)
            }
        }
    }
})
