<template>
  <div class="chart">
    <canvas id="results"></canvas>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Chart from 'chart.js'
import constants from '../objects/constants'

const colorSets = {
    blue: { solid: 'rgba(0, 107, 227, 1)', transparent: 'rgba(0, 107, 227, 0.1)' },
    green: { solid: 'rgba(0, 182, 76, 1)', transparent: 'rgba(0, 182, 76, 0.1)' },
    red: { solid: 'rgba(203, 12, 12, 1)', transparent: 'rgba(203, 12, 12, 0.1)' }
}

export default {
    props: {
        oneYearArray: { type: Array },
        nthYearArray: { type: Array },
        currentArray: { type: Array },
        year: { type: Number }
    },
    name: 'Chart',
    watch: {
        oneYearArray (newArray, oldArray) {
            this.resultChart.destroy()
            this.oneYearSubcriptionArray = newArray
            this.chartData()
        },
        nthYearArray (newArray, oldArray) {
            this.resultChart.destroy()
            this.nthYearSubcriptionArray = newArray
            this.nthYear = this.year
            this.chartData()
        },
        currentArray (newArray, oldArray) {
            this.resultChart.destroy()
            this.currentCostArray = newArray
            this.chartData()
        }
    },
    mounted () {
        this.chartData()
    },
    data () {
        return {
            resultChart: {},
            nthYearSubcriptionArray: [0, 0, 0, 0],
            oneYearSubcriptionArray: [0, 0, 0, 0],
            currentCostArray: [0, 0, 0, 0]
        }
    },
    computed: {
        ...mapState([
            'organizationInformation',
            'purchaseOptions'
        ])
    },
    methods: {
        chartData () {
            const datasets = [
                {
                    label: `With PrinterLogic (${this.nthYear} Year Subscription)`,
                    data: this.nthYearSubcriptionArray,
                    backgroundColor: colorSets['blue'].transparent,
                    borderColor: colorSets['blue'].solid,
                    pointBorderColor: colorSets['blue'].solid,
                    pointHoverBackgroundColor: colorSets['blue'].solid,
                    pointHoverBorderColor: colorSets['blue'].solid
                },
                {
                    label: 'With PrinterLogic (1 Year Subscription)',
                    data: this.oneYearSubcriptionArray,
                    backgroundColor: colorSets['red'].transparent,
                    borderColor: colorSets['red'].solid,
                    pointBorderColor: colorSets['red'].solid,
                    pointHoverBackgroundColor: colorSets['red'].solid,
                    pointHoverBorderColor: colorSets['red'].solid
                },
                {
                    label: 'Current Cost',
                    data: this.currentCostArray,
                    backgroundColor: colorSets['green'].transparent,
                    borderColor: colorSets['green'].solid,
                    pointBorderColor: colorSets['green'].solid,
                    pointHoverBackgroundColor: colorSets['green'].solid,
                    pointHoverBorderColor: colorSets['green'].solid
                }
            ]
            const chartCanvas = this.$el.querySelector('#results')
            this.resultChart = new Chart(chartCanvas, {
                type: 'line',
                data: {
                    labels: ['0yr', '1yr', '2yr', '3yr']
                },
                options: {
                    responsive: true,
                    title: {
                        display: true,
                        fontSize: 20,
                        text:
              [
                  (this.purchaseOptions.licenseType === constants.SAAS ? 'SaaS Subscription' : 'Virtual Appliance Subscription')
              ]
                    },
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback: (value, index, values) => {
                                    return `${value.toLocaleString('en-US', { style: 'currency', currency: this.organizationInformation.currency })}`
                                },
                                beginAtZero: true,
                                responsive: true,
                                maintainAspectRatio: true
                            }
                        }]
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            label: (tooltipItems, data) => {
                                return `${tooltipItems.yLabel.toLocaleString('en-US', { style: 'currency', currency: this.organizationInformation.currency })}`
                            }
                        }
                    }
                }
            })
            this.resultChart.data.datasets = datasets
            // const mediaQueryList = window.matchMedia('print')
            window.onbeforeprint = () => {
                this.resultChart.resize()
            }
            this.resultChart.update()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart {
  position: relative;
  margin: auto;
  height: 50vh;
  width: 49vw;
  max-width: 1250px;
  min-width: 950px;
}
@media print {
  .chart {
    position: relative;
    margin: auto;
    transform: scale(0.8) translate(-15%);
  }
}

@media screen and (max-width: 780px) {
  .chart {
    display: none;
  }
}
</style>
